@font-face {
  font-family: 'Alice';
  src: url('/assets/fonts/Alice-Regular.ttf') format('truetype');
}

body {
  background-color: #ffffff;
}

#banner {
  color: #ffffff;
  margin-bottom: 10px;
}

#banner h1 {
  color: #ffffff;
}

#banner a {
  color: white;
}

#banner a:hover {
  text-decoration: underline;
}

#menu {
  margin-top: 19px;
  margin-bottom: 0px;
  border-bottom: solid;
  border-width: thin;
}

#top {
  width: 100%;
  max-width: initial;
  background-color: #005b88;
  margin-bottom: 10px;
}

code {
  border-style: initial;
  border-width: initial;
  border-color: initial;
  color: initial;
  padding: initial;
  background-color: #f2f2f2;
}

iframe {
  max-width:100%
}

.banner {
  padding-left: 0rem;

  &__description {
    padding: 0 0 0.5rem 0;
  }
}

.highlight {
  margin-bottom: 1.25rem;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  overflow: auto;
  white-space: pre;
}

.highlighter-rouge {
  background-color: #f2f2f2;
}

#mc_embed_signup {
  background-color: #f0f8ff;
}

.clear input {
  margin-top: 1.3rem;
  padding: 0.6rem 1rem;
}

.excerpt {
  margin-bottom: 2rem;
}

.post {
  margin-bottom: 2rem;
}

.read-more {
  background-color: #005b88;
  color: #fff;
  display: block;
  margin-bottom: 2rem;
  padding: 0 0 .4rem .7rem;
  width: 7.2rem;
}

.read-more:after {
  content: '  \00BB';
  font-size: 24px;
}

.row {
  width: 50rem;
  max-width: 100%;
}

p {
  font-family: 'Alice', serif;
  line-height: 1.5;
}

ul li {
  margin-bottom: .4rem;
  font-family: 'Alice', serif;
}

ol li {
  margin-bottom: .4rem;
  font-family: 'Alice', serif;
}

ul.pagination {
  margin: 1rem auto;
}

ul.pagination li {
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 16px;
}

.arrow {
  height: initial;
  margin-left: initial;
}

.unavailable {
  padding: 0.0625rem 0.625rem 0.0625rem;
}
